import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FormConfirm from '../../components/FormConfirm'
import DataBaseLetters from '../../components/DataBaseLetters'
import { Box } from 'grommet'

const LettersConfirm = () => (
  <Layout productNav="letters">
    <SEO title="Letters - Confirm Order" />
    <Box pad={{ vertical: 'small' }} fill>
      <DataBaseLetters Component={FormConfirm} />
    </Box>
  </Layout>
)

export default LettersConfirm
